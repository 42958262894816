"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _Box = _interopRequireDefault(require("../Box"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n      display: inline-flex;\n      flex-direction: column;\n      justify-content: start;\n      align-items: start;\n      ", ";\n      ", ";\n    "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  justify-content: center;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  flex-direction: row-reverse;\n  > * {\n    border: 2px solid white;\n    margin-left: -8px;\n    transition: 0.125s ease-in-out;\n  }\n  &:hover {\n    > * {\n      margin-left: -2px;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  > * {\n    text-align: center;\n    align-items: center;\n    border: 1px solid transparent;\n  }\n  > *:first-child {\n    border-radius: 8px 0 0 0;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  > * {\n    justify-content: center;\n  }\n  > *:first-child {\n    border-radius: 8px 8px 0 0;\n  }\n  > *:last-child {\n    border-radius: 0 0 8px 8px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  > * {\n    text-align: center;\n    border-radius: 0;\n    margin: 0;\n    align-items: center;\n  }\n  > *:first-child {\n    border-top-left-radius: 8px;\n    border-bottom-left-radius: 8px;\n  }\n  > *:last-child {\n    border-top-right-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var HorizontalButtons = (0, _styledComponents.css)(_templateObject());
var VerticalButtons = (0, _styledComponents.css)(_templateObject2());
var HorizontalTabs = (0, _styledComponents.css)(_templateObject3());
var HorizontalAvatars = (0, _styledComponents.css)(_templateObject4());
var VerticalAvatars = (0, _styledComponents.css)(_templateObject5());
var Group = (0, _styledComponents["default"])(_Box["default"])(_templateObject6(), function (props) {
  return props.type === 'buttons' && HorizontalButtons;
}, function (props) {
  return props.type === 'avatars' && HorizontalAvatars;
}, function (props) {
  return props.type === 'tabs' && HorizontalTabs;
}, function (props) {
  return props.vertical && (0, _styledComponents.css)(_templateObject7(), function (props) {
    return props.type === 'buttons' && VerticalButtons;
  }, function (props) {
    return props.type === 'avatars' && VerticalAvatars;
  });
});
Group.defaultProps = {
  display: 'flex',
  alignItems: 'center'
};
var _default = Group;
exports["default"] = _default;