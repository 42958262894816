"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "GlobalStyle", {
  enumerable: true,
  get: function get() {
    return _utils["default"];
  }
});
exports["default"] = void 0;

var _space = require("./space");

var _utils = _interopRequireDefault(require("./utils"));

var _colors = _interopRequireDefault(require("./colors"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorStyles = {
  "default": {
    color: _colors["default"].primary,
    backgroundColor: _colors["default"].white
  },
  light: {
    color: _colors["default"].primary,
    backgroundColor: _colors["default"].light
  },
  purple: {
    color: _colors["default"].palette.purples[6],
    backgroundColor: _colors["default"].palette.purples[0]
  },
  red: {
    color: _colors["default"].palette.reds[6],
    backgroundColor: _colors["default"].palette.reds[0]
  },
  orange: {
    color: _colors["default"].palette.oranges[6],
    backgroundColor: _colors["default"].palette.oranges[0]
  },
  yellow: {
    color: _colors["default"].palette.yellows[6],
    backgroundColor: _colors["default"].palette.yellows[0]
  },
  green: {
    color: _colors["default"].palette.greens[6],
    backgroundColor: _colors["default"].palette.greens[0]
  },
  teal: {
    color: _colors["default"].palette.teals[6],
    backgroundColor: _colors["default"].palette.teals[0]
  },
  blue: {
    color: _colors["default"].palette.blues[6],
    backgroundColor: _colors["default"].palette.blues[0]
  },
  pink: {
    color: _colors["default"].palette.pinks[6],
    backgroundColor: _colors["default"].palette.pinks[0]
  },
  gray: {
    color: _colors["default"].palette.grays[6],
    backgroundColor: _colors["default"].palette.grays[0]
  }
};
var radius = ['0px', '2px', '3px', '4px', '6px', '8px', '16px', '32px', '50%']; // Radius alias

radius.none = radius[0];
radius.rounded = radius[5];
radius.pill = radius[6];
radius.circle = radius[8];

var radii = _objectSpread({}, radius);

radii.none = radius[0];
radii.rounded = radius[5];
radii.pill = radius[6];
radii.circle = radius[8];
var shapes = {
  square: {
    'border-radius': radius.none
  },
  rounded: {
    'border-radius': radius.rounded
  },
  circle: {
    'border-radius': radius.circle,
    padding: '0'
  },
  roundedTop: {
    'border-top-left-radius': radius.rounded,
    'border-top-right-radius': radius.rounded
  }
};
var fontFamily = {
  "default": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Ubuntu', sans-serif",
  monospace: 'monospace'
};
var buttonStyles = {
  "default": {
    color: _colors["default"].palette.grays[8],
    backgroundColor: _colors["default"].palette.grays[0],
    border: '1px solid',
    borderColor: 'transparent',
    '&:hover': {
      color: _colors["default"].palette.grays[8],
      backgroundColor: _colors["default"].palette.grays[1]
    },
    intents: {
      action: {
        color: _colors["default"].intents.action[0],
        backgroundColor: _colors["default"].intents.action[3],
        '&:hover': {
          color: _colors["default"].intents.action[0],
          backgroundColor: _colors["default"].intents.action[4]
        }
      },
      success: {
        color: _colors["default"].white,
        backgroundColor: _colors["default"].intents.success[3],
        '&:hover': {
          color: _colors["default"].intents.success[0],
          backgroundColor: _colors["default"].intents.success[4]
        }
      },
      danger: {
        color: _colors["default"].intents.danger[0],
        backgroundColor: _colors["default"].intents.danger[3],
        '&:hover': {
          color: _colors["default"].intents.danger[0],
          backgroundColor: _colors["default"].intents.danger[4]
        }
      },
      warning: {
        color: _colors["default"].intents.warning[3],
        backgroundColor: _colors["default"].intents.warning[1],
        '&:hover': {
          color: _colors["default"].intents.warning[0],
          backgroundColor: _colors["default"].intents.warning[3]
        }
      }
    }
  },
  darker: {
    color: _colors["default"].palette.grays[8],
    backgroundColor: _colors["default"].palette.grays[1],
    '&:hover': {
      color: _colors["default"].palette.grays[8],
      backgroundColor: _colors["default"].palette.grays[2]
    }
  },
  primary: {
    color: _colors["default"].palette.grays[7],
    backgroundColor: _colors["default"].white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0, .125)',
    boxShadow: '0 3px 3px -3px hsla(0, 0%, 0%, 0.2), inset 0 -4px 8px -8px hsla(0, 0%, 0%, 0.3)',
    '&:hover': {
      color: _colors["default"].palette.blues[5],
      borderColor: _colors["default"].palette.brands[2],
      backgroundColor: '',
      boxShadow: '0 3px 3px -3px hsla(0, 0%, 0%, 0.2)'
    },
    intents: {
      action: {
        color: _colors["default"].white,
        backgroundColor: _colors["default"].palette.blues[4],
        boxShadow: '0 3px 3px -3px hsla(0, 0%, 0%, 0.2), inset 0 -4px 8px -8px hsla(0, 0%, 0%, 0.3)',
        '&:hover': {
          color: _colors["default"].white,
          backgroundColor: _colors["default"].palette.blues[5],
          borderColor: _colors["default"].palette.blues[6],
          boxShadow: '0 3px 3px -3px hsla(0, 0%, 0%, 0.2)'
        }
      },
      success: {
        color: 'white',
        backgroundColor: _colors["default"].success,
        '&:hover': {
          color: 'white',
          backgroundColor: _colors["default"].palette.greens[6]
        }
      },
      danger: {
        color: 'white',
        backgroundColor: _colors["default"].palette.reds[5],
        '&:hover': {
          color: 'white',
          backgroundColor: _colors["default"].palette.reds[6]
        }
      }
    }
  },
  minimal: {
    color: _colors["default"].palette.grays[6],
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: _colors["default"].palette.grays[1]
    },
    intents: {
      action: {
        color: _colors["default"].action,
        backgroundColor: _colors["default"].white,
        '&:hover': {
          backgroundColor: _colors["default"].palette.blues[0]
        }
      },
      success: {
        color: _colors["default"].success,
        backgroundColor: _colors["default"].white,
        '&:hover': {
          backgroundColor: _colors["default"].palette.greens[0]
        }
      },
      danger: {
        color: _colors["default"].danger,
        backgroundColor: _colors["default"].white,
        '&:hover': {
          backgroundColor: _colors["default"].palette.reds[0]
        }
      }
    }
  },
  outline: {
    color: _colors["default"].palette.grays[6],
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: _colors["default"].palette.grays[2],
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: _colors["default"].palette.grays[1]
    },
    intents: {
      action: {
        color: _colors["default"].action,
        backgroundColor: 'transparent'
      },
      success: {
        color: _colors["default"].success,
        backgroundColor: 'transparent'
      },
      danger: {
        border: '1px solid',
        borderColor: _colors["default"].palette.reds[0],
        color: _colors["default"].palette.reds[6],
        backgroundColor: 'transparent'
      }
    }
  },
  custom: {
    '&:hover': {},
    intents: {
      action: {
        color: _colors["default"].action,
        backgroundColor: 'transparent'
      },
      success: {
        color: _colors["default"].success,
        backgroundColor: 'transparent'
      },
      danger: {
        color: _colors["default"].danger,
        backgroundColor: 'transparent'
      }
    }
  },
  buttonSizes: {
    xsmall: {
      fontSize: _space.fontSizes[2],
      padding: '0 .75rem',
      height: '1.75rem',
      lineHeight: '1.75rem'
    },
    small: {
      fontSize: _space.fontSizes[3],
      padding: '0 .875rem',
      height: '2rem',
      lineHeight: '2rem'
    },
    medium: {
      fontSize: _space.fontSizes[3],
      padding: '0 1rem',
      height: '2.5rem',
      lineHeight: '2.5rem'
    },
    large: {
      fontSize: _space.fontSizes[6],
      padding: '0 1.5rem',
      height: '2.75rem',
      lineHeight: '2.75rem'
    },
    custom: {}
  }
};
var layoutStyles = {
  horizontal: {
    flexDirection: 'row'
  },
  vertical: {
    flexDirection: 'column'
  }
};
var skeletonStyles = {
  "default": {
    backgroundColor: _colors["default"].palette.grays[2]
  },
  alt: {
    backgroundColor: _colors["default"].palette.grays[5]
  },
  clean: {
    backgroundColor: 'currentColor'
  },
  skeletonSizes: {
    xsmall: {
      height: '.5rem'
    },
    small: {
      height: '.75rem'
    },
    medium: {
      height: '1rem'
    },
    large: {
      height: '2rem'
    },
    xlarge: {
      height: '4rem'
    },
    xxlarge: {
      height: '8rem'
    }
  },
  skeletonTypes: {
    card: {
      height: '2rem',
      width: '2rem'
    },
    circle: {
      height: '2.5rem',
      width: '2.5rem',
      borderRadius: '50%',
      xsmall: {
        height: '1.5rem',
        width: '1.5rem',
        borderRadius: '50%'
      },
      small: {
        height: '2rem',
        width: '2rem',
        borderRadius: '50%'
      }
    },
    rounded: {
      height: '2.5rem',
      width: '2.5rem',
      borderRadius: '8px',
      small: {
        height: '2rem',
        width: '2rem'
      }
    },
    button: {
      height: '2.5rem',
      width: '5rem'
    }
  }
};

var tagStyles = _objectSpread(_objectSpread({}, colorStyles), {}, {
  "default": colorStyles.gray,
  priority: _objectSpread(_objectSpread({}, colorStyles.gray), {}, {
    status: {
      low: colorStyles.blue,
      medium: colorStyles.orange,
      high: colorStyles.red
    }
  }),
  intent: _objectSpread(_objectSpread({}, colorStyles.gray), {}, {
    status: {
      action: colorStyles.blue,
      warning: colorStyles.orange,
      danger: colorStyles.red,
      success: colorStyles.green
    }
  }),
  tagSizes: {
    xsmall: {
      fontSize: _space.fontSizes[0],
      padding: '0 .5rem',
      height: '17px',
      lineHeight: '1',
      fontWeight: 900
    },
    small: {
      fontSize: _space.fontSizes[1],
      padding: '0 .5rem',
      height: '21px',
      lineHeight: '1',
      fontWeight: 700
    },
    medium: {
      fontSize: _space.fontSizes[2],
      padding: '0 .75rem',
      height: '25px',
      lineHeight: '1',
      fontWeight: 700
    },
    large: {
      fontSize: _space.fontSizes[3],
      padding: '0 1rem',
      height: '29px',
      lineHeight: '1',
      fontWeight: 700
    },
    custom: {}
  }
});

var presets = {
  "default": {
    color: _colors["default"].primary,
    backgroundColor: _colors["default"].white
  },
  light: {
    color: _colors["default"].primary,
    backgroundColor: _colors["default"].white,
    borderRadius: radius.rounded,
    border: '1px solid',
    borderColor: _colors["default"].light
  },
  success: {
    color: _colors["default"].white,
    backgroundColor: _colors["default"].success,
    borderRadius: radius.rounded,
    border: '1px solid',
    borderColor: _colors["default"].intents.success[4]
  }
};
var shadowStyles = {
  none: {
    boxShadow: '0px 0px 0px rgba(0,0,0,0.125)'
  },
  small: {
    boxShadow: '0px 2px 8px rgba(0,0,0,0.125)'
  },
  medium: {
    boxShadow: '0px 8px 16px rgba(0,0,0,0.125)'
  }
};
var theme = {
  colors: _colors["default"],
  breakpoints: _space.breakpoints,
  space: _space.space,
  radius: radius,
  radii: radii,
  shapes: shapes,
  fontFamily: fontFamily,
  fontSizes: _space.fontSizes,
  iconSizes: _space.iconSizes,
  textSizes: _space.textSizes,
  headingSizes: _space.headingSizes,
  buttonStyles: buttonStyles,
  layoutStyles: layoutStyles,
  skeletonStyles: skeletonStyles,
  avatarSizes: _space.avatarSizes,
  badgeSizes: _space.badgeSizes,
  switchSizes: _space.switchSizes,
  tagStyles: tagStyles,
  colorStyles: colorStyles,
  presets: presets,
  shadowStyles: shadowStyles
};
var _default = theme;
exports["default"] = _default;