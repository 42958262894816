"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _chromaJs = _interopRequireDefault(require("chroma-js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Definition of available colors from the spectre in hsl(0-360, 0-1, 0-1)
var spectre = {
  white: _chromaJs["default"].hsl(0, 0, 1),
  black: _chromaJs["default"].hsl(0, 0, 0),
  red: _chromaJs["default"].hsl(3, 1, 0.6),
  orange: _chromaJs["default"].hsl(35, 1, 0.6),
  yellow: _chromaJs["default"].hsl(48, 1, 0.6),
  green: _chromaJs["default"].hsl(129, 0.5, 0.6),
  teal: _chromaJs["default"].hsl(175, 0.67, 0.5),
  blue: _chromaJs["default"].hsl(211, 1, 0.5),
  purple: _chromaJs["default"].hsl(280, 0.67, 0.6),
  pink: _chromaJs["default"].hsl(349, 1, 0.6)
};

var Color = _chromaJs["default"].hsl(120, 1, 0.2); // Construct base system color to be used in UI


var systemColor = (0, _chromaJs["default"])(Color).set('hsl.s', 0.25).set('hsl.l', 0.5);
var system = {
  brand: Color,
  accent: spectre.blue,
  neutral: systemColor,
  light: (0, _chromaJs["default"])(systemColor).set('hsl.l', 0.96),
  dark: (0, _chromaJs["default"])(systemColor).set('hsl.l', 0.16),
  gray: systemColor,
  slategray: _chromaJs["default"].mix(Color, systemColor, 0.7),
  red: _chromaJs["default"].mix(spectre.red, Color, 0.125),
  orange: _chromaJs["default"].mix(spectre.orange, Color, 0.125),
  yellow: _chromaJs["default"].mix(spectre.yellow, Color, 0.25),
  green: _chromaJs["default"].mix(spectre.green, Color, 0.125),
  teal: _chromaJs["default"].mix(spectre.teal, Color, 0.25),
  blue: _chromaJs["default"].mix(spectre.blue, Color, 0.25),
  purple: _chromaJs["default"].mix(spectre.purple, Color, 0.25),
  pink: _chromaJs["default"].mix(spectre.pink, Color, 0.25)
};
var intent = {
  action: system.accent,
  success: system.green,
  danger: system.red,
  warning: system.yellow
};
var scales = {};
Object.keys(system).map(function (item) {
  scales[item + 's'] = _chromaJs["default"].scale([spectre.white, system[item], spectre.black]).domain([0, 0.5, 1]).mode('lab').padding([0.05, 0.2]).colors(10);
  return scales;
});
var intents = {};
Object.keys(intent).map(function (item) {
  intents[item] = _chromaJs["default"].scale([spectre.white, intent[item], spectre.black]).correctLightness().domain([0, 0.5, 1]).mode('lab').padding([0.05, 0.2]).colors(7);
  return intents;
});

var colors = _objectSpread(_objectSpread({}, system), {}, {
  palette: _objectSpread({}, scales),
  intents: _objectSpread({}, intents),
  white: spectre.white,
  black: spectre.black,
  primary: scales.grays[8],
  secondary: scales.grays[6],
  tertiary: scales.grays[4],
  //
  action: intent.action,
  success: intent.success,
  danger: intent.danger,
  warning: intent.warning
});

var _default = colors;
exports["default"] = _default;