"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledSystem = require("styled-system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Box = _styledComponents["default"].div(_templateObject(), _styledSystem.color, _styledSystem.space, _styledSystem.layout, _styledSystem.flexbox, _styledSystem.border, _styledSystem.background, _styledSystem.position, _styledSystem.grid, _styledSystem.shadow, _utils.shape, _utils.presets, function (props) {
  return props.pointer && _utils.pointer;
}, _utils.hover, _utils.hoverbg, _utils.hoverbr);

Box.defaultProps = {
  boxSizing: 'border-box'
};
Box.propTypes = {
  boxSizing: _propTypes["default"].string
};
var _default = Box;
exports["default"] = _default;