// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-index-mdx": () => import("./../../../packages/index.mdx" /* webpackChunkName: "component---packages-index-mdx" */),
  "component---packages-props-mdx": () => import("./../../../packages/props.mdx" /* webpackChunkName: "component---packages-props-mdx" */),
  "component---packages-reactberry-core-src-avatar-index-mdx": () => import("./../../../packages/reactberry:core/src/Avatar/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-avatar-index-mdx" */),
  "component---packages-reactberry-core-src-badge-index-mdx": () => import("./../../../packages/reactberry:core/src/Badge/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-badge-index-mdx" */),
  "component---packages-reactberry-core-src-box-index-mdx": () => import("./../../../packages/reactberry:core/src/Box/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-box-index-mdx" */),
  "component---packages-reactberry-core-src-button-index-mdx": () => import("./../../../packages/reactberry:core/src/Button/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-button-index-mdx" */),
  "component---packages-reactberry-core-src-card-index-mdx": () => import("./../../../packages/reactberry:core/src/Card/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-card-index-mdx" */),
  "component---packages-reactberry-core-src-divider-index-mdx": () => import("./../../../packages/reactberry:core/src/Divider/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-divider-index-mdx" */),
  "component---packages-reactberry-core-src-dropdown-index-mdx": () => import("./../../../packages/reactberry:core/src/Dropdown/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-dropdown-index-mdx" */),
  "component---packages-reactberry-core-src-group-index-mdx": () => import("./../../../packages/reactberry:core/src/Group/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-group-index-mdx" */),
  "component---packages-reactberry-core-src-heading-index-mdx": () => import("./../../../packages/reactberry:core/src/Heading/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-heading-index-mdx" */),
  "component---packages-reactberry-core-src-icon-index-mdx": () => import("./../../../packages/reactberry:core/src/Icon/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-icon-index-mdx" */),
  "component---packages-reactberry-core-src-link-index-mdx": () => import("./../../../packages/reactberry:core/src/Link/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-link-index-mdx" */),
  "component---packages-reactberry-core-src-loader-index-mdx": () => import("./../../../packages/reactberry:core/src/Loader/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-loader-index-mdx" */),
  "component---packages-reactberry-core-src-placeholder-index-mdx": () => import("./../../../packages/reactberry:core/src/Placeholder/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-placeholder-index-mdx" */),
  "component---packages-reactberry-core-src-progress-index-mdx": () => import("./../../../packages/reactberry:core/src/Progress/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-progress-index-mdx" */),
  "component---packages-reactberry-core-src-segment-index-mdx": () => import("./../../../packages/reactberry:core/src/Segment/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-segment-index-mdx" */),
  "component---packages-reactberry-core-src-svg-index-mdx": () => import("./../../../packages/reactberry:core/src/Svg/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-svg-index-mdx" */),
  "component---packages-reactberry-core-src-tabs-index-mdx": () => import("./../../../packages/reactberry:core/src/Tabs/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-tabs-index-mdx" */),
  "component---packages-reactberry-core-src-tag-index-mdx": () => import("./../../../packages/reactberry:core/src/Tag/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-tag-index-mdx" */),
  "component---packages-reactberry-core-src-text-index-mdx": () => import("./../../../packages/reactberry:core/src/Text/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-text-index-mdx" */),
  "component---packages-reactberry-core-src-theme-default-theme-mdx": () => import("./../../../packages/reactberry:core/src/Theme/default/theme.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-theme-default-theme-mdx" */),
  "component---packages-reactberry-core-src-toggle-index-mdx": () => import("./../../../packages/reactberry:core/src/Toggle/index.mdx" /* webpackChunkName: "component---packages-reactberry-core-src-toggle-index-mdx" */),
  "component---packages-reactberry-forms-src-checkbox-index-mdx": () => import("./../../../packages/reactberry:forms/src/Checkbox/index.mdx" /* webpackChunkName: "component---packages-reactberry-forms-src-checkbox-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

