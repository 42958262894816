"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styledSystem = require("styled-system");

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _Box = _interopRequireDefault(require("../Box"));

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  align-items: center;\n  cursor: pointer;\n\n  transition: all 0.125s ease-out;\n  text-align: center;\n  text-decoration: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var buttonStyle = (0, _styledSystem.variant)({
  key: 'buttonStyles'
});
var buttonSize = (0, _styledSystem.variant)({
  key: 'buttonStyles.buttonSizes',
  prop: 'buttonSize'
});
var buttonShape = (0, _styledSystem.variant)({
  key: 'shapes',
  prop: 'shape'
});
var intents = {
  "default": (0, _styledSystem.variant)({
    key: 'buttonStyles.default.intents',
    prop: 'intent'
  }),
  primary: (0, _styledSystem.variant)({
    key: 'buttonStyles.primary.intents',
    prop: 'intent'
  }),
  minimal: (0, _styledSystem.variant)({
    key: 'buttonStyles.minimal.intents',
    prop: 'intent'
  }),
  outline: (0, _styledSystem.variant)({
    key: 'buttonStyles.outline.intents',
    prop: 'intent'
  }),
  custom: (0, _styledSystem.variant)({
    key: 'buttonStyles.custom.intents',
    prop: 'intent'
  })
};
var buttonStyling = (0, _styledComponents.css)(_templateObject());
var disabledStyling = (0, _styledComponents.css)(_templateObject2(), _utils.disabled);
var Button = (0, _styledComponents["default"])(_Box["default"])(_templateObject3(), buttonStyling, buttonStyle, function (props) {
  return intents[props.variant];
}, buttonSize, buttonShape, function (props) {
  return props.disabled && disabledStyling;
}, function (props) {
  return props.disabled && props.variant !== 'custom' && "\n  background: none;\n  ";
});
Button.defaultProps = {
  // default props definitions
  display: 'inline-flex',
  variant: 'default',
  buttonSize: 'medium',
  fontWeight: 600,
  shape: 'rounded',
  mr: 'xxxsmall',
  justifyContent: 'center'
};
var _default = Button;
exports["default"] = _default;