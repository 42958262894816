"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalStyle = exports.presets = exports.shape = exports.textSize = exports.hoverbr = exports.hoverbg = exports.hover = exports.disabled = exports.pointer = exports.uppercase = exports.underline = exports.truncate = void 0;

var _styledSystem = require("styled-system");

var _styledComponents = require("styled-components");

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\nbody {\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Helvetica Neue\", \"Ubuntu\", sans-serif;\n  padding: 0;\n  margin: 0;\n  line-height: 1.5;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  text-rendering: optimizeLegibility;\n  font-feature-settings: \"pnum\";\n  font-variant-numeric: proportional-nums;\n}\nimg { max-width: 100%; }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  opacity: 0.6;\n  pointer-events: none;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: underline;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: inline-block;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

// Text Truncating
var truncate = (0, _styledComponents.css)(_templateObject());
exports.truncate = truncate;
var underline = (0, _styledComponents.css)(_templateObject2());
exports.underline = underline;
var uppercase = (0, _styledComponents.css)(_templateObject3());
exports.uppercase = uppercase;
var pointer = (0, _styledComponents.css)(_templateObject4());
exports.pointer = pointer;
var disabled = (0, _styledComponents.css)(_templateObject5()); // Hover prop support

exports.disabled = disabled;
var hover = (0, _styledSystem.style)({
  // React prop name
  prop: 'hover',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'color',
  // Key for theme values
  key: 'colors'
}); // Hover Background prop support

exports.hover = hover;
var hoverbg = (0, _styledSystem.style)({
  // React prop name
  prop: 'hoverbg',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'background',
  // Key for theme values
  key: 'colors'
});
exports.hoverbg = hoverbg;
var hoverbr = (0, _styledSystem.style)({
  // React prop name
  prop: 'hoverbr',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'border-color',
  // Key for theme values
  key: 'colors'
});
exports.hoverbr = hoverbr;
var textSize = (0, _styledSystem.variant)({
  key: 'textSizes',
  prop: 'fontSize'
});
exports.textSize = textSize;
var shape = (0, _styledSystem.variant)({
  key: 'shapes',
  prop: 'shape'
});
exports.shape = shape;
var presets = (0, _styledSystem.variant)({
  key: 'colorStyles',
  prop: 'preset'
});
exports.presets = presets;
var GlobalStyle = (0, _styledComponents.createGlobalStyle)(_templateObject6());
exports.GlobalStyle = GlobalStyle;