import { Playground, Props } from 'docz';
import { Tabs, TabList, Tab, TabPanel } from "../../../../../packages/reactberry:core/src/Tabs";
import * as React from 'react';
export default {
  Playground,
  Props,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  React
};