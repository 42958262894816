import { Playground } from 'docz';
import { Button, Box, Heading } from '@reactberry/core';
import theme from "../../../../../packages/reactberry:core/src/Theme/default";
import * as React from 'react';
export default {
  Playground,
  Button,
  Box,
  Heading,
  theme,
  React
};