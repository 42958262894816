"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useDropdown;

var _react = require("react");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function useDropdown(dropEl, actionEl) {
  dropEl = dropEl.current;
  actionEl = actionEl.current;

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      drop = _useState2[0],
      setDrop = _useState2[1];

  var toggleDrop = (0, _react.useCallback)(function (toggleState) {
    setDrop(toggleState !== undefined ? Boolean(toggleState) : !drop);
  }, [drop]);
  var onWindowClick = (0, _react.useCallback)(function (ev) {
    var clickOnAction = actionEl && (ev.target === actionEl || actionEl.contains(ev.target));
    var clickOnDrop = dropEl && (ev.target === dropEl || dropEl.contains(ev.target));

    if (!clickOnAction && !clickOnDrop && drop === true) {
      toggleDrop(false);
    }
  }, [drop, actionEl, dropEl, toggleDrop]);
  var onEsc = (0, _react.useCallback)(function (ev) {
    if (ev.keyCode === 27 && drop === true) {
      toggleDrop(false);
    }
  }, [drop, toggleDrop]);
  (0, _react.useEffect)(function () {
    window.addEventListener("click", onWindowClick);
    return function () {
      return window.removeEventListener("click", onWindowClick);
    };
  });
  (0, _react.useEffect)(function () {
    window.addEventListener("keyup", onEsc);
    return function () {
      return window.removeEventListener("keyup", onEsc);
    };
  });
  return [drop, toggleDrop];
}